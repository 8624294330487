import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import "./TimelinePanel.css";
import { printDate } from "./Agenda";
import dayjs from "dayjs";
import { deleteWorkLocation, getWorkLocations, setWorkLocation, getWorkHours } from "../services/api";
import homeIcon from "../images/home.svg";
import officeIcon from "../images/apartment.svg";
import supportIcon from "../images/support_agent.svg";
import sickIcon from "../images/sick.svg";
import vacationIcon from "../images/beach_access.svg";
import workTripIcon from "../images/travel.svg";
import careOfChildIcon from "../images/child_care.svg";
import meetingIcon from "../images/handshake.svg";
import timeIcon from "../images/schedule.svg";
import Modal from "./Modal";
import AdjustWorkHoursForm from "./AdjustWorkHoursForm";

const weekdayToIsoDayOfWeek = w => {
    switch (w) {
        case 0: return 7;
        case 1: return 1;
        case 2: return 2;
        case 3: return 3;
        case 4: return 4;
        case 5: return 5;
        case 6: return 6;
        default: return -1;
    }
};

export default memo(({
    selectedDateRange,
    selectedUsers,
    users,
    notWorkingDates,
    token,
    ownUser,
    supports,
    supportGroups,
    absences,
    defaultWorkHours,
    modalDiv
}) => {
    const userList = useMemo(() => {
        const list = [];
        if (selectedUsers && selectedUsers.size > 0) {
            for (const s of selectedUsers.values()) {
                const u = (users || []).find(u => u.userId === s);
                if (u) {
                    list.push(u);
                }
            }
        } else {
            if (users != null) {
                for (const u of users) {
                    list.push(u);
                }
            }
        }

        list.sort((a, b) => {
            const comp0 = a.firstName.localeCompare(b.firstName);
            return comp0 === 0 ? a.lastName.localeCompare(b.lastName) : comp0;
        });
        return list;
    }, [selectedUsers, users]);

    const [today, setToday] = useState(() => dayjs().startOf("day"));
    const [tomorrow, setTomorrow] = useState(() => dayjs().startOf("day").add(1, "day"));
    const [saving, setSaving] = useState(false);

    const [editingWorkHours, setEditingWorkHours] = useState(null);
    const [workHours, setWorkHours] = useState([]);

    useEffect(() => {
        const t = setInterval(() => {
            setToday(dayjs().startOf("day"));
            setTomorrow(dayjs().startOf("day").add(1, "day"));
        }, 1000 * 60);
        return () => {
            clearInterval(t);
        };
    }, []);

    useEffect(() => {
        (async () => {
            setWorkHours((await getWorkHours(token, selectedDateRange[0], selectedDateRange[1])).data);
        })();
    }, [token, selectedDateRange]);

    const saveWorkHours = useCallback(wh => {
        setWorkHours([...workHours.filter(w => w.workDate !== wh.workDate), wh]);
    }, [workHours, setWorkHours]);

    const removeWorkHours = useCallback(date => {
        setWorkHours(workHours.filter(w => w.workDate !== date));
    }, [workHours, setWorkHours]);

    const [workLocations, setWorkLocations] = useState([]);

    useEffect(() => {
        const load = (async () => {
            setWorkLocations((await getWorkLocations(token, selectedDateRange[0], selectedDateRange[1], selectedUsers && [...selectedUsers])).data);
        });
        load();
        const interval = setInterval(load, 1000 * 60);
        return () => {
            clearInterval(interval);
        };
    }, [token, selectedDateRange, selectedUsers]);

    const dates = useMemo(() => {
        const ds = [];

        const from = dayjs(selectedDateRange[0]).startOf("day");
        const to = dayjs(selectedDateRange[1]).endOf("day");

        let at = from;

        while (at.isSameOrBefore(to, "day")) {
            const date = at.format("YYYY-MM-DD");
            const isoWeekday = at.isoWeekday();
            const currentAt = at;
            ds.push({
                date,
                dayJsDate: currentAt,
                formattedDate: printDate(at, today, tomorrow, true),
                isSunday: currentAt.isoWeekday() === 7,
                notWorking: notWorkingDates.find(n => n.date === date),
                isInPast: at.isBefore(today, "day"),
                workHours: workHours.find(w => w.workDate === date),
                defaultWorkHours: defaultWorkHours.find(d => weekdayToIsoDayOfWeek(d.weekday) === isoWeekday),
                userData: Object.assign({}, ...((users || []).map(u => ({
                    [u.userId]: {
                        workLocation: workLocations.find(wl => wl.workDate === date && wl.userId === u.userId)?.location,
                        supports: supports.filter(s => s.date === date && s.userId === u.userId),
                        absences: absences.filter(a => a.userId === u.userId && dayjs(a.startDate).isSameOrBefore(currentAt, "date") && (!a.endDate || dayjs(a.endDate).isSameOrAfter(currentAt)))
                    }
                })))),
                numberWorkingAtOffice: workLocations.filter(wl => wl.workDate === date && wl.location === "Office").reduce((set, wl) => set.add(wl.userId), new Set()).size,
                numberWorkingAtHome: workLocations.filter(wl => wl.workDate === date && wl.location === "Home").reduce((set, wl) => set.add(wl.userId), new Set()).size,
                numberWithVacation: absences.filter(a => a.kind === "Vacation" && dayjs(a.startDate).isSameOrBefore(currentAt, "date") && (!a.endDate || dayjs(a.endDate).isSameOrAfter(currentAt))).reduce((set, a) => set.add(a.userId), new Set()).size
            });
            at = at.add(1, "day");
        }

        return ds;
    }, [selectedDateRange, users, today, tomorrow, notWorkingDates, supports, absences, workLocations, workHours, defaultWorkHours]);

    const weeks = useMemo(() => {
        const w = [];
        let atWeek = null;
        for (const d of dates) {
            if (!atWeek || (d.dayJsDate.isoWeek() !== atWeek)) {
                atWeek = d.dayJsDate.isoWeek();
                w.push({
                    week: atWeek,
                    nrOfDays: 1
                });
            } else {
                w[w.length - 1].nrOfDays += 1;
            }
        }

        return w;
    }, [dates]);

    return (
        <div className="timeline panel" style={{
            display: "grid",
            gridTemplateColumns: `repeat(${dates.length + 1}, max-content)`
        }}>
            <h2>Timeline</h2>
            <div></div>
            {dates.map(d => (
                <div key={`${d.formattedDate}`} className={`timeline-date${d.isSunday ? " sunday" : ""}`}>{d.formattedDate}</div>
            ))}
            <div></div>
            {
                weeks.map((w, idx) => (
                    <div key={idx} className="timeline-week" style={{
                        gridColumn: `span ${w.nrOfDays}`
                    }}>
                        {w.week}
                    </div>
                ))
            }
            {userList.map(user => (
                <React.Fragment key={user.userId}>
                    <div className="name">{user.firstName} {user.lastName}</div>
                    {dates.map(d => (
                        <div key={`${user.userId}-${d.formattedDate}`} className={`slot${d.isSunday ? " sunday" : ""}${d.notWorking ? " not-working" : ""}`} title={d.formattedDate}>
                            {
                                (user.userId === ownUser.userId && !d.isInPast) ? (
                                    <><img src={officeIcon} alt="Office" title="Office" className={`work-office-button own-user${d.userData[user.userId]?.workLocation === "Office" ? " current" : ""}`} onClick={saving ? undefined : (async e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setSaving(true);
                                        try {
                                            const userData = d.userData[user.userId];
                                            if (userData && userData.workLocation !== "Office") {
                                                const workLocation = (await setWorkLocation(token, ownUser.userId, d.date, "Office")).data;
                                                setWorkLocations([...workLocations.filter(wl => !(wl.userId === workLocation.userId && wl.workDate === workLocation.workDate)), workLocation]);
                                            } else {
                                                await deleteWorkLocation(token, d.date);
                                                setWorkLocations(workLocations.filter(wl => !(wl.userId === ownUser.userId && d.date === wl.workDate)));
                                            }
                                        } finally {
                                            setSaving(false);
                                        }
                                    })}/><img src={homeIcon} alt="Home" title="Home" className={`work-office-button own-user${d.userData[user.userId]?.workLocation === "Home" ? " current" : ""}`} onClick={saving ? undefined : (async e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setSaving(true);
                                        try {
                                            const userData = d.userData[user.userId];
                                            if (userData && userData.workLocation !== "Home") {
                                                const workLocation = (await setWorkLocation(token, ownUser.userId, d.date, "Home")).data;
                                                setWorkLocations([...workLocations.filter(wl => !(wl.userId === workLocation.userId && wl.workDate === workLocation.workDate)), workLocation]);
                                            } else {
                                                await deleteWorkLocation(token, d.date);
                                                setWorkLocations(workLocations.filter(wl => !(wl.userId === ownUser.userId && d.date === wl.workDate)));
                                            }
                                        } finally {
                                            setSaving(false);
                                        }
                                    })}/></>
                                ) : (
                                    <>
                                        {
                                            d.userData[user.userId]?.workLocation === "Office" && (
                                                <img alt="Office" title="Office" src={officeIcon}/>
                                            )
                                        }
                                        {
                                            d.userData[user.userId]?.workLocation === "Home" && (
                                                <img alt="Home" title="Home" src={homeIcon}/>
                                            )
                                        }
                                    </>
                                )
                            }
                            {
                                !d.isInPast && user.userId === ownUser.userId && d.workHours && (
                                    <div className="workhours" title={`${d.workHours.startTime} - ${d.workHours.endTime}`} onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setEditingWorkHours({
                                            date: d.date,
                                            formattedDate: d.formattedDate,
                                            workHours: d.workHours,
                                            isRemovable: true
                                        });
                                    }}>
                                        <div className="from">{d.workHours.startTime}</div>
                                        <div className="to">{d.workHours.endTime}</div>
                                    </div>
                            )
                            }
                            {
                                !d.isInPast && user.userId === ownUser.userId && !d.workHours && d.defaultWorkHours && (
                                    <div className="workhours default" title={`${d.defaultWorkHours.startTime} - ${d.defaultWorkHours.endTime}`} onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setEditingWorkHours({
                                            date: d.date,
                                            formattedDate: d.formattedDate,
                                            workHours: d.defaultWorkHours,
                                            isRemovable: false
                                        });
                                    }}>
                                        <div className="from">{d.defaultWorkHours.startTime}</div>
                                        <div className="to">{d.defaultWorkHours.endTime}</div>
                                    </div>
                                )
                            }
                            {
                                !d.isInPast && user.userId === ownUser.userId && !d.workHours && !d.defaultWorkHours && (
                                    <img className="no-work-hours" src={timeIcon} alt="Set work hours" title="Set work hours" onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setEditingWorkHours({
                                            date: d.date,
                                            formattedDate: d.formattedDate,
                                            workHours: null,
                                            isRemovable: false
                                        });
                                    }}/>
                                )
                            }
                            {
                                d.userData[user.userId]?.supports.map(s => (
                                    <img key={s.supportGroupId} alt={supportGroups.find(g => g.id === s.supportGroupId)?.name} src={supportIcon} title={supportGroups.find(g => g.id === s.supportGroupId)?.name}/>
                                ))
                            }
                            {
                                d.userData[user.userId]?.absences.find(a => a.kind === "Vacation") && (
                                    <img src={vacationIcon} alt="Vacation" title="Vacation"/>
                                )
                            }
                            {
                                d.userData[user.userId]?.absences.find(a => a.kind === "Sick") && (
                                    <img src={sickIcon} alt="Sick" title="Sick"/>
                                )
                            }
                            {
                                d.userData[user.userId]?.absences.find(a => a.kind === "Meeting") && (
                                    <img src={meetingIcon} alt="Meeting" title="Meeting"/>
                                )
                            }
                            {
                                d.userData[user.userId]?.absences.find(a => a.kind === "WorkTrip") && (
                                    <img src={workTripIcon} alt="Work trip" title="Work Trip"/>
                                )
                            }
                            {
                                d.userData[user.userId]?.absences.find(a => a.kind === "CareOfChild") && (
                                    <img src={careOfChildIcon} alt="Care of child" title="Care Of Child"/>
                                )
                            }
                        </div>
                    ))}
                </React.Fragment>
            ))}
            <div></div>
            {dates.map(d => (
                <div key={`sum-${d.formattedDate}`} className={`sum-row ${d.isSunday ? " sunday" : ""}`}>
                    {d.numberWorkingAtOffice > 0 && (
                        <div className="icon-with-count">
                            <img src={officeIcon} alt="Office" title="Office"/>
                            <div className="count">{d.numberWorkingAtOffice}</div>
                        </div>
                    )}
                    {d.numberWorkingAtHome > 0 && (
                        <div className="icon-with-count">
                            <img src={homeIcon} alt="Home" title="Home"/>
                            <div className="count">{d.numberWorkingAtHome}</div>
                        </div>
                    )}
                    {d.numberWithVacation > 0 && (
                        <div className="icon-with-count">
                            <img src={vacationIcon} alt="Vacation" title="Vacation"/>
                            <div className="count">{d.numberWithVacation}</div>
                        </div>
                    )}
                </div>
            ))}
            {
                editingWorkHours && (
                    <Modal modalDiv={modalDiv} onCancel={() => {
                        setEditingWorkHours(null);
                    }}>
                        <AdjustWorkHoursForm date={editingWorkHours.date} formattedDate={editingWorkHours.formattedDate} workHours={editingWorkHours.workHours} defaultWorkHours={editingWorkHours.defaultWorkHours} isRemovable={editingWorkHours.isRemovable} ownUser={ownUser} token={token} saveWorkHours={saveWorkHours} removeWorkHours={removeWorkHours} onOk={() => {
                            setEditingWorkHours(null);
                        }}/>
                    </Modal>
                )
            }
        </div>
    );
});