import React, { memo, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { printDate, printDateTime } from "./Agenda";
import editIcon from "../images/edit.svg";
import "./MyAbsencePanel.css";
import Modal from "./Modal";
import EditAbsenceForm from "./EditAbsenceForm";

export default memo(({
    absences,
    session,
    upsertAbsence,
    removeAbsence,
    modalDiv
}) => {
    const [now, setNow] = useState(dayjs());
    const [today, setToday] = useState(() => dayjs().startOf("day"));
    const [tomorrow, setTomorrow] = useState(() => dayjs().startOf("day").add(1, "day"));
    const [editingAbsence, setEditingAbsence] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(dayjs());
            setToday(dayjs().startOf("day"));
            setTomorrow(dayjs().startOf("day").add(1, "day"));
        }, 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const sortedAbsences = useMemo(() => {
        const as = [];
        for (const a of absences) {
            const start = a.startTime ? dayjs(`${a.startDate} ${a.startTime}`) : dayjs(a.startDate).startOf("day");
            const end = a.endDate && (a.endTime ? dayjs(`${a.endDate} ${a.endTime}`) : dayjs(a.endDate).endOf("day"));

            as.push(Object.assign({}, a, {
                dayjsStart: start,
                dayjsEnd: end,
                ongoing: now.isSameOrAfter(start) && (!end || now.isSameOrBefore(end))
            }));
        }
        as.sort((a, b) => a.dayjsStart.unix() - b.dayjsStart.unix());
        return as;
    }, [absences, now]);

    return (
        <div className="my-absence panel">
            <h2>Upcoming absence for {session?.user?.firstName}&nbsp;{session?.user?.lastName}</h2>
            <div className="header">Type</div>
            <div className="header">From</div>
            <div className="header">To</div>
            <div className="header">Certainty</div>
            <div className="header">Comment</div>
            <div className="header"></div>
            {sortedAbsences.map(a => (
                <React.Fragment key={a.absenceId}>
                    <div className={a.ongoing ? "kind ongoing" : "kind"}>
                        <label>Type</label>
                        {(() => {
                            switch (a.kind) {
                                case "Lunch": return "Lunch";
                                case "Meeting": return "Meeting";
                                case "FinishedForTheDay": return "Finished for the day";
                                case "Vacation": return "Vacation";
                                case "Sick": return "Sick";
                                case "TemporarilyOut": return "Temporarily out";
                                case "WorkTrip": return "Work trip";
                                case "CareOfChild": return "Care of child"
                                default: return "Unknown";
                            }
                        })()}
                        {(a.kind === "Vacation") && a.vacationAccepted && (" (accepted)") }
                        {(a.kind === "Vacation") && !a.vacationAccepted && (" (not accepted)") }
                    </div>
                    <div className={a.ongoing ? "from ongoing" : "from"}><label></label>
                        <label>From</label>
                        {(() => {
                        if (a.startTime) {
                            return printDateTime(a.dayjsStart, today, tomorrow);
                        } else {
                            return printDate(a.dayjsStart, today, tomorrow);
                        }
                    })()}</div>
                    <div className={a.ongoing ? "to ongoing" : "to"}>
                        <label>To</label>
                        {(() => {
                        if (a.dayjsEnd && a.endTime) {
                            return printDateTime(a.dayjsEnd, today, tomorrow);
                        } else if (a.dayjsEnd) {
                            return printDate(a.dayjsEnd, today, tomorrow);
                        } else {
                            return "";
                        }
                    })()}</div>
                    <div className={a.ongoing ? "certainty ongoing" : "certainty"}>
                        <label>Certainty</label>
                        {a.status}
                    </div>
                    <div className={`comment${(!a.comment || a.comment.length === 0) ? " empty" : ""}${a.ongoing ? " ongoing" : ""}`}><label>Comment</label>{a.comment}</div>
                    {
                        ((a.userId === session.user.userId) || session.user.admin) ? (
                            <div className="edit with-action" onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                                setEditingAbsence(a);
                            }}><img src={editIcon} alt=""/> Edit</div>
                        ) : (
                            <div className="edit"></div>
                        )
                    }
                </React.Fragment>
            ))}
            {
                editingAbsence && (
                    <Modal modalDiv={modalDiv} onCancel={() => {
                        setEditingAbsence(null);
                    }}>
                        <EditAbsenceForm absence={editingAbsence} session={session} upsertAbsence={upsertAbsence} removeAbsence={removeAbsence} onOk={() => {
                            setEditingAbsence(null);
                        }}/>
                    </Modal>
                )
            }
        </div>
    );
});