import dayjs from "dayjs";
import React, { memo, useEffect, useMemo, useState } from "react";
import "./Agenda.css";
import editIcon from "../images/edit.svg";
import EditAbsenceForm from "./EditAbsenceForm";
import Modal from "./Modal";

export const printDate = (d, today, tomorrow, humanize = false) => {
    if (humanize && today.isSame(d, "date")) {
        return "Today";
    } else if (humanize && tomorrow.isSame(d, "date")) {
        return "Tomorrow";
    } else if (today.isSame(d, "year")) {
        return d.format("ddd MMM D");
    } else {
        return d.format("ddd MMM D YYYY");
    }
};

export const printTime = (d) => {
    return d.format("HH:mm");
};

export const printDateTime = (d, today, tomorrow, humanize = false) => {
    if (humanize && today.isSame(d, "date")) {
        return `Today ${d.format("HH:mm")}`;
    } else if (humanize && tomorrow.isSame(d, "date")) {
        return `Tomorrow ${d.format("HH:mm")}`;
    } else if (today.isSame(d, "year")) {
        return d.format("ddd MMM D HH:mm");
    } else {
        return d.format("ddd MMM D YYYY");
    }
};

export default memo(({
    selectedDateRange,
    absences,
    notWorkingDates,
    users,
    upsertAbsence,
    session,
    modalDiv,
    removeAbsence
}) => {
    const [today, setToday] = useState(() => dayjs().startOf("day"));
    const [tomorrow, setTomorrow] = useState(() => dayjs().startOf("day").add(1, "day"));
    const [now, setNow] = useState(dayjs());
    const [editingAbsence, setEditingAbsence] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(dayjs());
            setToday(dayjs().startOf("day"));
            setTomorrow(dayjs().startOf("day").add(1, "day"));
        }, 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const dates = useMemo(() => {
        const ds = [];

        const from = dayjs(selectedDateRange[0]).startOf("day");
        const to = dayjs(selectedDateRange[1]).endOf("day");

        const sortedAbsences = absences.sort((a, b) => dayjs((a.startTime ? `${a.startDate} ${a.startTime}` : a.startDate)).unix() - dayjs(b.startTime ? `${b.startDate} ${b.startTime}` : b.startDate).unix());

        let at = from;

        while (at.isSameOrBefore(to, "day")) {
            const as = [];
            for (const a of sortedAbsences) {
                const start = a.startTime ? dayjs(`${a.startDate} ${a.startTime}`) : dayjs(a.startDate).startOf("day");
                const end = a.endDate && (a.endTime ? dayjs(`${a.endDate} ${a.endTime}`) : dayjs(a.endDate).endOf("day"));

                if ((!end || end.isSameOrAfter(at, "day")) && start.isSameOrBefore(at, "day")) {
                    as.push(Object.assign({}, a, {
                        dayjsStart: start,
                        dayjsEnd: end,
                        ongoing: now.isSame(at, "day") && now.isSameOrAfter(start) && (!end || now.isSameOrBefore(end))
                    }));
                }
            }

            const atDateString = at.format("YYYY-MM-DD");

            ds.push({
                date: at,
                formattedDate: printDate(at, today, tomorrow, true),
                absences: as,
                notWorking: notWorkingDates.find(n => n.date === atDateString)
            });
            at = at.add(1, "day");
        }

        return ds;
    }, [selectedDateRange, absences, notWorkingDates, today, tomorrow, now]);

    return (
        <div className="agenda panel">
            <h2>Agenda</h2>
            <div className="header"></div>
            <div className="header">User</div>
            <div className="header">Type</div>
            <div className="header">From</div>
            <div className="header">To</div>
            <div className="header">Certainty</div>
            <div className="header">Comment</div>
            {dates.map(d => (
                <React.Fragment key={d.formattedDate}>
                    <title className={`agenda-date-title${d.notWorking ? " not-working" : ""}`}>{d.formattedDate}</title>
                    {d.absences.map(a => (
                        <React.Fragment key={a.absenceId}>
                            <div className={a.ongoing ? "time ongoing" : "time"}>
                            <label>When</label>
                                {(() => {
                                    if (a.startTime && a.endTime && a.dayjsStart.isSame(d.date, "day") && a.dayjsEnd.isSame(d.date, "day")) {
                                        return `${printTime(a.dayjsStart)} - ${printTime(a.dayjsEnd)}`;
                                    } else if (!a.startTime && !a.endTime) {
                                        return "All day";
                                    } else if (a.dayjsStart.isBefore(d.date, "day") && (!a.dayjsEnd || a.dayjsEnd.isAfter(d.date, "day"))) {
                                        return "All day";
                                    } else if (a.dayjsEnd && a.dayjsStart.isBefore(d.date, "day")) {
                                        return `Until ${printTime(a.dayjsEnd)}`;
                                    } else {
                                        return `From ${printTime(a.dayjsStart)}`;
                                    }
                                })()}
                            </div>
                            <div className={a.ongoing ? "user ongoing" : "user"}>
                                <label>User</label>
                                {(() => {
                                    let user = (users || []).find(u => u.userId === a.userId);
                                    if (user) {
                                        return `${user.firstName} ${user.lastName}`;
                                    } else {
                                        return "";
                                    }
                                })()}
                            </div>
                            <div className={a.ongoing ? "kind ongoing" : "kind"}>
                                <label>Type</label>
                                {(() => {
                                    switch (a.kind) {
                                        case "Lunch": return "Lunch";
                                        case "Meeting": return "Meeting";
                                        case "FinishedForTheDay": return "Finished for the day";
                                        case "Vacation": return "Vacation";
                                        case "Sick": return "Sick";
                                        case "TemporarilyOut": return "Temporarily out";
                                        case "WorkTrip": return "Work trip";
                                        case "CareOfChild": return "Care of child"
                                        default: return "Unknown";
                                    }
                                })()}
                                {(a.kind === "Vacation") && a.vacationAccepted && (" (accepted)") }
                                {(a.kind === "Vacation") && !a.vacationAccepted && (" (not accepted)") }
                            </div>
                            <div className={a.ongoing ? "from ongoing" : "from"}><label></label>
                                <label>From</label>
                                {(() => {
                                if (a.startTime) {
                                    return printDateTime(a.dayjsStart, today, tomorrow);
                                } else {
                                    return printDate(a.dayjsStart, today, tomorrow);
                                }
                            })()}</div>
                            <div className={a.ongoing ? "to ongoing" : "to"}>
                                <label>To</label>
                                {(() => {
                                if (a.dayjsEnd && a.endTime) {
                                    return printDateTime(a.dayjsEnd, today, tomorrow);
                                } else if (a.dayjsEnd) {
                                    return printDate(a.dayjsEnd, today, tomorrow);
                                } else {
                                    return "";
                                }
                            })()}</div>
                            <div className={a.ongoing ? "certainty ongoing" : "certainty"}>
                                <label>Certainty</label>
                                {a.status}
                            </div>
                            <div className={`comment${(!a.comment || a.comment.length === 0) ? " empty" : ""}${a.ongoing ? " ongoing" : ""}`}><label>Comment</label>{a.comment}</div>
                            {
                                ((a.userId === session.user.userId) || session.user.admin) ? (
                                    <div className="edit with-action" onClick={e => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setEditingAbsence(a);
                                    }}><img src={editIcon} alt=""/> Edit</div>
                                ) : (
                                    <div className="edit"></div>
                                )
                            }
                        </React.Fragment>
                    ))}
                </React.Fragment>
            ))}
            {
                editingAbsence && (
                    <Modal modalDiv={modalDiv} onCancel={() => {
                        setEditingAbsence(null);
                    }}>
                        <EditAbsenceForm absence={editingAbsence} session={session} upsertAbsence={upsertAbsence} removeAbsence={removeAbsence} onOk={() => {
                            setEditingAbsence(null);
                        }}/>
                    </Modal>
                )
            }
        </div>
    );
});