import axios from 'axios';

let onUnauthorized = null;

const api = axios.create({
  baseURL: '/api', // Change to your .NET API URL if different,
  validateStatus: status => {
    if (onUnauthorized && status === 401) {
      onUnauthorized();
    }
    return status >= 200 && status < 300; // default
  },
  paramsSerializer: {
    indexes: null
  }
});

const baseApi = axios.create({
  baseURL: '/', // Change to your .NET API URL if different,
  validateStatus: status => {
    if (onUnauthorized && status === 401) {
      onUnauthorized();
    }
    return status >= 200 && status < 300; // default
  },
  paramsSerializer: {
    indexes: null
  }
});

export const setOnUnauthorized = callback => onUnauthorized = callback;

export const getUsers = (token) => api.get('/users', { headers: { Authorization: `Bearer ${token}` } });
export const getUser = (token, id) => api.get(`/users/${id}`, { headers: { Authorization: `Bearer ${token}` } });
export const createUser = (token, user) => api.post('/users', user, { headers: { Authorization: `Bearer ${token}` } });
export const updateUser = (token, id, user) => api.put(`/users/${id}`, user, { headers: { Authorization: `Bearer ${token}` } });
export const deleteUser = (token, id) => api.delete(`/users/${id}`, { headers: { Authorization: `Bearer ${token}` } });

export const getWorkLocations = (token, from, to, users) => api.get('/worklocations', { headers: { Authorization: `Bearer ${token}` }, params: { from, to, users: users ? users.join("|") : null } });
export const setWorkLocation = (token, userId, workDate, location) => api.post('/worklocations', { userId, workDate, location }, { headers: { Authorization: `Bearer ${token}` } });
export const deleteWorkLocation = (token, workDate) => api.delete(`/worklocations/${encodeURIComponent(workDate)}`, { headers: { Authorization: `Bearer ${token}` } });

export const getAbsences = (token, from, to, users) => api.get('/absences', { headers: { Authorization: `Bearer ${token}` }, params: { from, to, users: users ? users.join("|") : null} });
export const postAbsence = (token, absence) => api.post('/absences', absence, { headers: { Authorization: `Bearer ${token}` } });
export const deleteAbsence = (token, id) => api.delete(`/absences/${id}`, { headers: { Authorization: `Bearer ${token}` } });
export const getNotAcceptedVacation = (token) => api.get('/absences/notaccepted', { headers: { Authorization: `Bearer ${token}` } });

export const getSupports = (token, from, to, users) => api.get('/supports', { headers: { Authorization: `Bearer ${token}` }, params: { from, to, users: users ? users.join("|") : null} });
export const getSupportGroups = (token) => api.get('/supports/groups', { headers: { Authorization: `Bearer ${token}` } });
export const postSupport = (token, support) => api.post('/supports', support, { headers: { Authorization: `Bearer ${token}` } });
export const deleteSupport = (token, support) => api.delete(`/supports/${support.supportGroupId}/${support.daste}/${support.userId}`, { headers: { Authorization: `Bearer ${token}` } });

export const getUsersPresets = (token) => api.get('/userspresets', { headers: { Authorization: `Bearer ${token}` } });
export const createUsersPreset = (token, name, userIds) => api.post("/userspresets/", { name, userIds }, { headers: { Authorization: `Bearer ${token}` } });
export const updateUsersPreset = (token, id, name, userIds) => api.post(`/userspresets/${id}`, { name, userIds }, { headers: { Authorization: `Bearer ${token}` } });
export const deleteUsersPreset = (token, id) => api.delete(`/userspresets/${id}`, { headers: { Authorization: `Bearer ${token}` } });

export const getDefaultWorkHours = (token) => api.get('/defaultworkhours', { headers: { Authorization: `Bearer ${token}` } });
export const postDefaultWorkHours = (token, defaultWorkHours) => api.post('/defaultworkhours', {defaultWorkHours}, { headers: { Authorization: `Bearer ${token}` } });

export const getWorkHours = (token, from, to) => api.get('/workhours', { headers: { Authorization: `Bearer ${token}` }, params: { from, to } });
export const postWorkHours = (token, workHours) => api.post('/workhours', workHours, { headers: { Authorization: `Bearer ${token}` } });
export const deleteWorkHours = (token, workDate) => api.delete(`/workhours/${workDate}`, { headers: { Authorization: `Bearer ${token}` } });

export const getNotWorkingDates = (token, from, to) => api.get('/notworkingdates', { headers: { Authorization: `Bearer ${token}` }, params: { from, to } });

export const login = (username, password, remember) => api.post('/auth/login', { username, password, remember });
export const renewToken = (renewToken) => api.get('/auth/renew', { headers: { Authorization: `Bearer ${renewToken}` } });
export const syncUsers = (token, username, password) => api.post('/auth/sync', { username, password }, { headers: { Authorization: `Bearer ${token}` } });

export const getTelavoxIcal = (token) => api.get('/icaltoken/telavox', { headers: { Authorization: `Bearer ${token}` } });
export const getIcal = (token, users) => api.get('/icaltoken/users', { headers: { Authorization: `Bearer ${token}` }, params: { users: users ? users.join("|") : null} });

export const revokeSlackTokens = (token) => baseApi.delete('/oauth/slack', { headers: { Authorization: `Bearer ${token}` } });
export const getSlackStatus = (token) => baseApi.get('/oauth/slack', { headers: { Authorization: `Bearer ${token}` } });