import React, { memo, useMemo, useState } from "react";
import { postDefaultWorkHours } from "../services/api";
import "./AdjustDefaultWorkHoursForm.css";
import saveIcon from "../images/save_white.svg";

const printDay = day => {
    switch (day) {
        case 0: return "Sunday";
        case 1: return "Monday";
        case 2: return "Tuesday";
        case 3: return "Wednesday";
        case 4: return "Thursday";
        case 5: return "Friday";
        case 6: return "Saturday";
        default: return "Unknown";
    }
};

export default memo(({
    token,
    ownUser,
    onOk,
    defaultWorkHours,
    setDefaultWorkHours
}) => {
    const [newDefaultWorkHours, setNewDefaultWorkHours] = useState(defaultWorkHours);
    const [saving, setSaving] = useState(false);

    const disabled = useMemo(() => saving || newDefaultWorkHours.find(wh => (wh.startTime && !wh.endTime) || (wh.endTime && !wh.startTime) || (wh.startTime && wh.endTime && (wh.endTime < wh.startTime))) != null, [newDefaultWorkHours, saving]);

    return (
        <div className="adjust-default-work-hours">
            <h2>Default work hours</h2>
            <select value={newDefaultWorkHours.length === 0 ? "false" : "true"} onChange={e => {
                if (e.target.value === "false") {
                    setNewDefaultWorkHours([]);
                } else {
                    setNewDefaultWorkHours([1, 2, 3, 4, 5].map(d => ({
                        weekday: d,
                        startTime: "08:00",
                        endTime: "18:00",
                        userId: ownUser.userId
                    })));
                }
            }}>
                <option value="false">Not using default work hours</option>
                <option value="true">Using default work hours</option>
            </select>
            {newDefaultWorkHours.length > 0 && (
                [1, 2, 3, 4, 5, 6, 0].map(day => (
                    <React.Fragment key={day}>
                        <title>{printDay(day)}</title>
                        <input type="time" value={newDefaultWorkHours.find(d => d.weekday === day)?.startTime} onChange={e => {
                            const newValue = Object.assign({}, newDefaultWorkHours.find(d => d.weekday === day) ?? {}, {
                                weekday: day,
                                startTime: e.target.value,
                                userId: ownUser.userId
                            });

                            setNewDefaultWorkHours([...newDefaultWorkHours.filter(d => d.weekday !== day), newValue]);
                        }}/>
                        <input type="time" value={newDefaultWorkHours.find(d => d.weekday === day)?.endTime} onChange={e => {
                            const newValue = Object.assign({}, newDefaultWorkHours.find(d => d.weekday === day) ?? {}, {
                                weekday: day,
                                endTime: e.target.value,
                                userId: ownUser.userId
                            });

                            setNewDefaultWorkHours([...newDefaultWorkHours.filter(d => d.weekday !== day), newValue]);
                        }}/>
                    </React.Fragment>
                ))
            )}
            <button onClick={async e => {
                e.preventDefault();
                e.stopPropagation();

                setSaving(true);

                try {
                    await postDefaultWorkHours(token, newDefaultWorkHours);
                    setDefaultWorkHours(newDefaultWorkHours);
                    onOk();
                } finally {
                    setSaving(false);
                }
            }} disabled={disabled}>
                <img src={saveIcon} alt=""/> Save
            </button>
        </div>
    );
});