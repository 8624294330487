import React, { memo, useEffect, useState } from "react";
import { postAbsence, syncUsers } from "../services/api";
import syncIcon from "../images/sync_white.svg";
import "./AdminPanel.css";
import { printDate, printDateTime } from "./Agenda";
import dayjs from "dayjs";

export default memo(({
    token,
    users,
    upsertAbsence,
    notAcceptedVacation
}) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [today, setToday] = useState(() => dayjs().startOf("day"));
    const [tomorrow, setTomorrow] = useState(() => dayjs().startOf("day").add(1, "day"));

    useEffect(() => {
        const t = setInterval(() => {
            setToday(dayjs().startOf("day"));
            setTomorrow(dayjs().startOf("day").add(1, "day"));
        }, 1000 * 60);
        return () => {
            clearInterval(t);
        };
    }, []);

    return (
        <div className="admin panel">
            <h2>Admin actions</h2>
            <h3>Not accepted vacation</h3>
            <div className="not-yet-accepted-vacation">
                <div>Name</div>
                <div>From</div>
                <div>To</div>
                <div>Status</div>
                <div>Comment</div>
                <div></div>
                {notAcceptedVacation.map(a => (
                    <React.Fragment key={a.absenceId}>
                        <div>{(users || []).find(u => u.userId === a.userId)?.firstName} {(users || []).find(u => u.userId === a.userId)?.lastName}</div>
                        <div>{a.startTime ? printDateTime(dayjs(`${a.startDate} ${a.startTime}`), today, tomorrow, true) : printDate(dayjs(a.startDate), today, tomorrow, true)}</div>
                        <div>{(a.endDate && (a.endTime ? printDateTime(dayjs(`${a.endDate} ${a.endTime}`), today, tomorrow, true) : printDate(dayjs(a.endDate), today, tomorrow, true)))}</div>
                        <div>{a.status}</div>
                        <div>{a.comment}</div>
                        <button onClick={async e => {
                            e.preventDefault();
                            e.stopPropagation();
                            await postAbsence(token, {
                                ...a,
                                vacationAccepted: true
                            });
                            upsertAbsence({
                                ...a,
                                vacationAccepted: true
                            });
                        }}>Accept</button>
                    </React.Fragment>
                ))}
            </div>
            <h3>Force user/domain sync</h3>
            <input type="text" onChange={e => {
                setUsername(e.target.value);
            }} placeholder="Domain user"/>
            <input type="password" onChange={e => {
                setPassword(e.target.value);
            }} placeholder="Domain password"/>
            <button onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                syncUsers(token, username, password);
                setUsername("");
                setPassword("");
            }}><img src={syncIcon} alt=""/> Sync users</button>
        </div>
    );
});