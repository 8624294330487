import React, { memo, useMemo, useState, useEffect } from "react";
import { printDate } from "./Agenda";
import dayjs from "dayjs";
import "./Support.css";
import deleteIcon from "../images/delete.svg";
import { deleteSupport } from "../services/api";

export default memo(({
    supportGroups,
    supports,
    selectedDateRange,
    notWorkingDates,
    users,
    ownUser,
    removeSupport,
    token
}) => {
    const supportsPerDate = useMemo(() => {
        const visibleGroupIds = new Set();
        for (const s of supports) {
            visibleGroupIds.add(s.supportGroupId);
        }

        const visibleGroups = [];
        for (const v of visibleGroupIds) {
            const s = supportGroups.find(sg => sg.id === v);
            if (s) {
                visibleGroups.push(s);
            }
        }

        if (visibleGroups.length === 0) {
            return [];
        }

        let at = dayjs(selectedDateRange[0]).startOf("day");
        const to = dayjs(selectedDateRange[1]).endOf("day");

        const dates = [];

        while (at.isSameOrBefore(to, "day")) {
            const atDateString = at.format("YYYY-MM-DD");

            const supportForAt = {
                date: at,
                groups: [],
                notWorking: notWorkingDates.find(n => n.date === atDateString)
            };

            const currentAt = at;
            visibleGroups.forEach(group => {
                supportForAt.groups.push({
                    group,
                    users: supports.filter(s => s.supportGroupId === group.id && dayjs(s.date).isSame(currentAt, "day"))
                });
            });

            dates.push(supportForAt);

            at = at.add(1, "day");
        }

        return dates;
    }, [supportGroups, supports, selectedDateRange, notWorkingDates]);

    const userDict = useMemo(() => Object.assign({}, ...(users || []).map(u => ({[u.userId] : u}))), [users]);

    const [today, setToday] = useState(() => dayjs().startOf("day"));
    const [tomorrow, setTomorrow] = useState(() => dayjs().startOf("day").add(1, "day"));

    useEffect(() => {
        const t = setInterval(() => {
            setToday(dayjs().startOf("day"));
            setTomorrow(dayjs().startOf("day").add(1, "day"));
        }, 1000 * 60);
        return () => {
            clearInterval(t);
        };
    }, []);

    return (
        <div className="supports panel">
            <h2>Support</h2>
            {supportsPerDate.map(s => (
                <React.Fragment key={s.date.format("YYYY-MM-DD")}>
                    <title className={s.notWorking ? "not-working" : ""}>{printDate(dayjs(s.date), today, tomorrow, true)}</title>
                    {s.groups.map(g => (
                        <React.Fragment key={`${s.date.format("YYYY-MM-DD")}-${g.group.id}`}>
                            <div className="group-name">{g.group.name}</div>
                            {
                                g.users.length === 0 ? (
                                    <div className="support-user">-</div>
                                ) : g.users.map(support => (
                                    <React.Fragment key={`${s.date.format("YYYY-MM-DD")}-${g.group.id}-${support.userId}`}>
                                        <div className="support-user">{userDict[support.userId]?.firstName} {userDict[support.userId]?.lastName}</div>
                                        <div className="remove">{(support.manual && (support.user || ownUser.adminId === ownUser.userId || ownUser.admin)) && (
                                            <div className="with-action" onClick={async e => {
                                                e.stopPropagation();
                                                e.preventDefault();

                                                await deleteSupport(token, support);
                                                removeSupport(support);
                                            }}><img src={deleteIcon} alt=""/> Remove</div>
                                        )}</div>
                                    </React.Fragment>
                                ))
                            }
                        </React.Fragment>
                    ))}
                </React.Fragment>
            ))}
        </div>
    );
});