import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { login, renewToken as apiRenewToken, setOnUnauthorized, getUsers, getAbsences, getNotWorkingDates, getSupports, getSupportGroups, getDefaultWorkHours, getNotAcceptedVacation } from './services/api';
import "./App.css";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import Calendar from './components/Calendar';
import Users from './components/Users';
import Agenda from './components/Agenda';
import AdminPanel from './components/AdminPanel';
import ActionsPanel from './components/ActionsPanel';
import Support from './components/Support';
import "./fonts/fonts.css";
import TimelinePanel from './components/TimelinePanel';
import logo from "./images/logo.svg";
import MyAbsencePanel from './components/MyAbsencePanel';

dayjs.extend(isoWeek);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const App = () => {
  const [session, setSession] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem("nov_presence_session"));
    } catch (_) { }
    return null;
  });
  
  const [renewingToken, setRenewingToken] = useState(false);
  const [credentials, setCredentials] = useState({ username: '', password: '', remember: false });
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [absences, setAbsences] = useState([]);
  const [ownAbsences, setOwnAbsences] = useState([]);
  const [notAcceptedVacation, setNotAcceptedVacation] = useState([]);
  const [supports, setSupports] = useState([]);
  const [supportGroups, setSupportGroups] = useState([]);
  const [notWorkingDates, setNotWorkingDates] = useState([]);
  const [invalidateUsersPresetsCounter, setInvalidateUsersPresetsCounter] = useState(1);
  const invalidateUsersPresets = useCallback(() => {
    setInvalidateUsersPresetsCounter(invalidateUsersPresetsCounter + 1);
  }, [invalidateUsersPresetsCounter, setInvalidateUsersPresetsCounter]);
  const [defaultWorkHours, setDefaultWorkHours] = useState([]);
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  
  const modalsRef = useRef();
  const logout = useCallback(() => {
    setSession(null);
    setUsers([]);
    setSelectedUsers(null);
    setAbsences([]);
    setSupports([]);
    setSupportGroups([]);
    setNotWorkingDates([]);
    localStorage?.clear();
  }, [setSession, setUsers, setSelectedUsers, setAbsences, setSupports, setSupportGroups, setNotWorkingDates]);

  const token = session?.token;
  const renewToken = session?.renewToken;
  const tokenExpiration = session?.tokenExpiration;
  const renewTokenExpiration = session?.renewTokenExpiration;

  const [timer, setTimer] = useState(() => Date.now());

  const hasValidSession = useMemo(() => {
    return tokenExpiration && (new Date(tokenExpiration).getTime() - timer - 30000) > 0;
  }, [tokenExpiration, timer]);

  const upsertAbsence = useCallback(absence => {
    setAbsences([...absences.filter(a => a.absenceId !== absence.absenceId), absence]);
    if (absence.userId === session.user.userId) {
      setOwnAbsences([...ownAbsences.filter(a => a.absenceId !== absence.absenceId), absence]);
    }
    if (absence.vacationAccepted) {
      setNotAcceptedVacation(notAcceptedVacation.filter(a => a.absenceId !== absence.absenceId));
    }
  }, [absences, ownAbsences, session, notAcceptedVacation]);

  const removeAbsence = useCallback(absenceId => {
    setAbsences(absences.filter(a => a.absenceId !== absenceId));
    setOwnAbsences(ownAbsences.filter(a => a.absenceId !== absenceId));
  }, [absences, ownAbsences, setAbsences, setOwnAbsences]);

  const upsertSupport = useCallback(support => {
    if (Array.isArray(support)) {
      setSupports([...supports.filter(s => !support.find(innerSupport => s.date === innerSupport.date && s.supportGroupId === innerSupport.supportGroupId && s.userId === innerSupport.userId)), ...support]);
    } else {
      setSupports([...supports.filter(s => !(s.date === support.date && s.supportGroupId === support.supportGroupId && s.userId === support.userId)), support]);
    }
  }, [supports]);

  const removeSupport = useCallback(support => {
    setSupports(supports.filter(s => !(s.date === support.date && s.supportGroupId === support.supportGroupId && s.userId === support.userId)));
  }, [supports]);

  useEffect(() => {
    let checking = false;

    const check = async () => {
      setTimer(Date.now());

      if (!checking) {
        checking = true;
        if ((new Date(tokenExpiration).getTime() - new Date().getTime()) < (30 * 60 * 1000))
        {
          if (new Date(renewTokenExpiration).getTime() > new Date().getTime()) {
            try {
              setRenewingToken(true);
              const response = await apiRenewToken(renewToken);
              setSession(response.data);

              if (localStorage) {
                localStorage.setItem("nov_presence_session", JSON.stringify(response.data))
              }
            } catch(_) {
              setSession(null);
              localStorage.clear();
            } finally {
              setRenewingToken(false);
            }
          } else {
            setSession(null);
          }
        }
        checking = false;
      }
    };
    check();
    const i = setInterval(check, 60000);

    return () => {
      clearInterval(i);
    };
  }, [token, tokenExpiration, renewTokenExpiration, renewToken, setRenewingToken, setSession, setTimer]);

  useEffect(() => {
    (async () => {
      if (hasValidSession) {
        const data = (await getDefaultWorkHours(token)).data;
        setDefaultWorkHours(data);
      } else {
        setDefaultWorkHours([]);
      }
    })();
}, [hasValidSession, token]);

  useEffect(() => {
    window.addEventListener("storage", event => {
      if (event.key === null) {
        setSession(null);
      } else if (event.key === "nov_presence_session") {
        try {
          setSession(JSON.parse(event.newValue));
        } catch(_) {
          setSession(null);
          localStorage.clear();
        }
      }
    });

    setOnUnauthorized(() => {
      setSession(null);
      localStorage.clear();
    });
  }, []);

  useEffect(() => {
    if (hasValidSession) {
      (async () => {
        setUsers((await getUsers(token)).data);
      })();
      (async () => {
        setSupportGroups((await getSupportGroups(token)).data);
      })();
    } else {
      setUsers(null);
      setSelectedUsers(null);
    }
  }, [token, hasValidSession]);

  const handleLogin = async (event) => {
    event.stopPropagation();
    event.preventDefault();

    try {
      setRenewingToken(true);
      setInvalidCredentials(false);
      const response = await login(credentials.username, credentials.password, credentials.remember);
      setSession(response.data);
      setInvalidCredentials(false);
      setCredentials({ username: '', password: '', remember: false });

      if (localStorage) {
        localStorage.setItem("nov_presence_session", JSON.stringify(response.data))
      }
    } catch (error) {
      setCredentials({ username: credentials.username, password: '', remember: credentials.remember });
      if (error?.response?.status === 401) {
        setInvalidCredentials(true);
      } else {
        alert(error);
      }
    } finally {
      setRenewingToken(false);
    }
  };

  const [selectedDateRange, setSelectedDateRange] = useState(() => {
    const from = dayjs();
    const to = from.add(6, "day");
    return [from.format("YYYY-MM-DD"), to.format("YYYY-MM-DD")];
  });

  useEffect(() => {
    if (hasValidSession) {
      const load = (async () => {
        setAbsences((await getAbsences(session.token, selectedDateRange[0], selectedDateRange[1], selectedUsers && [...selectedUsers])).data);
      });
      load();
      const interval = setInterval(load, 1000 * 60);
      return () => {
        clearInterval(interval);
      };
    } else {
      setAbsences([]);
    }
  }, [token, selectedUsers, selectedDateRange, session, hasValidSession]);

  useEffect(() => {
    if (hasValidSession) {
      (async () => {
        setNotWorkingDates((await getNotWorkingDates(session.token, selectedDateRange[0], selectedDateRange[1])).data);
        if (session.user.admin) {
          setNotAcceptedVacation((await getNotAcceptedVacation(token)).data);
        } else {
          setNotAcceptedVacation([]);
        }
      })();
      const load = (async () => {
        setSupports((await getSupports(session.token, selectedDateRange[0], selectedDateRange[1])).data);
      });
      load();
      const interval = setInterval(load, 1000 * 60 * 15);
      return () => {
        clearInterval(interval);
      };
    } else {
      setNotWorkingDates([]);
      setSupports([]);
      setNotAcceptedVacation([]);
    }
  }, [token, selectedDateRange, session, hasValidSession]);

  useEffect(() => {
    if (hasValidSession) {
      const load = (async () => {
        setOwnAbsences((await getAbsences(session.token, dayjs().format("YYYY-MM-DD"), dayjs().add(100, "year").format("YYYY-MM-DD"), [session.user.userId])).data);
      });
      load();
      const interval = setInterval(load, 1000 * 60);
      return () => {
        clearInterval(interval);
      };
    } else {
      setOwnAbsences([]);
    }
  }, [selectedUsers, session, hasValidSession, setOwnAbsences]);

  const onClickLogo = useCallback(() => {
    const from = dayjs();
    const to = from.add(6, "day");
    setSelectedDateRange([from.format("YYYY-MM-DD"), to.format("YYYY-MM-DD")]);
    setSelectedUsers(null);
  }, [setSelectedDateRange, setSelectedUsers]);

  if (!session) {
    return (
      <div className="login-page">
        <form className="login-form" onSubmit={handleLogin} disabled={renewingToken}>
          <div className="logo-container">
            <img src={logo} alt=""/>
          </div>
          <h1><span className="presence">Presence</span><span className="at">@</span><span className="noventus">Noventus</span></h1>
          <input
            type="text"
            placeholder="Username in domain"
            value={credentials.username}
            onChange={(e) => setCredentials({ ...credentials, username: e.target.value })}
          />
          <input
            type="password"
            placeholder="Password"
            value={credentials.password}
            onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
          />
          {
            invalidCredentials && (
              <div className="invalid-credentials">Invalid credentials</div>
            )
          }
          <label>
            <input
              type="checkbox"
              checked={credentials.remember}
              onChange={(e) => setCredentials({ ...credentials, remember: e.target.checked })}
            />
            Remember me
          </label>
          <input type="submit" value="Sign in" />
        </form>
      </div>
    );
  }

  if (!hasValidSession) {
    return (
      <div className="login-page">
        Refreshing session...
      </div>
    );
  }

  return (
    <>
      <main>
        <title className="clickable" onClick={onClickLogo}><img className="title-logo" src={logo} alt=""/><span className="presence">Presence</span><span className="at">@</span><span className="noventus">Noventus</span></title>
        <Calendar selectedDateRange={selectedDateRange} setSelectedDateRange={setSelectedDateRange} session={session}/>
        <ActionsPanel modalDiv={modalsRef.current} selectedUsers={selectedUsers} selectedDateRange={selectedDateRange} session={session} logout={logout} token={token} ownUser={session.user} upsertAbsence={upsertAbsence} upsertSupport={upsertSupport} invalidateUsersPresets={invalidateUsersPresets} defaultWorkHours={defaultWorkHours} setDefaultWorkHours={setDefaultWorkHours} supportGroups={supportGroups} users={users}/>
        <Users selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} users={users} token={token} invalidateUsersPresetsCounter={invalidateUsersPresetsCounter}/>
        <div className="agenda-and-support">
          <Agenda selectedDateRange={selectedDateRange} absences={absences} notWorkingDates={notWorkingDates} users={users} upsertAbsence={upsertAbsence} removeAbsence={removeAbsence} session={session} modalDiv={modalsRef.current}/>
          <Support supportGroups={supportGroups} supports={supports} selectedDateRange={selectedDateRange} notWorkingDates={notWorkingDates} upsertSupport={upsertSupport} users={users} ownUser={session.user} removeSupport={removeSupport} token={token}/>
          <MyAbsencePanel session={session} absences={ownAbsences} upsertAbsence={upsertAbsence} removeAbsence={removeAbsence} modalDiv={modalsRef.current}/>
        </div>
        <TimelinePanel selectedDateRange={selectedDateRange} selectedUsers={selectedUsers} users={users} notWorkingDates={notWorkingDates} token={token} ownUser={session.user} supports={supports} supportGroups={supportGroups} absences={absences} defaultWorkHours={defaultWorkHours} modalDiv={modalsRef.current}/>
        {session.user.admin && (
          <AdminPanel token={token} selectedUsers={selectedUsers} modalDiv={modalsRef.current} users={users} notAcceptedVacation={notAcceptedVacation} upsertAbsence={upsertAbsence}/>
        )}
      </main>
      <div className="modals" ref={modalsRef}></div>
    </>
  );
};

export default App;
