import React, { memo, useCallback } from "react";
import { createPortal } from "react-dom";
import "./Modal.css";

export default memo(({modalDiv, children, onCancel}) => {
    const onClickBackground = useCallback(e => {
        e.stopPropagation();

        if (onCancel) {
            onCancel();
        }
    }, [onCancel]);

    const stopPropagation = useCallback(e => {
        e.stopPropagation();
    }, []);

    if (!modalDiv) {
        return null;
    }

    return createPortal((
        <div className="full-screen-modal" onClick={onClickBackground}>
            <div className="modal-panel" onClick={stopPropagation} onTouchMove={stopPropagation} onTouchStart={stopPropagation} onTouchEnd={stopPropagation} onMouseMove={stopPropagation} onMouseDown={stopPropagation} onMouseUp={stopPropagation} onScroll={stopPropagation}>
                {children}
            </div>            
        </div>
    ), modalDiv);
});