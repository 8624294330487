import dayjs from "dayjs";
import React, { useCallback, useMemo, useState, useEffect, memo } from "react";
import "./Calendar.css";
import { getNotWorkingDates } from "../services/api";
import leftIconWhite from "../images/chevron_left_white.svg"
import rightIconWhite from "../images/chevron_right_white.svg"

export const monthName = nr => {
    switch(nr) {
        case 0: return "January";
        case 1: return "February";
        case 2: return "March";
        case 3: return "April";
        case 4: return "May";
        case 5: return "June";
        case 6: return "July";
        case 7: return "August";
        case 8: return "September";
        case 9: return "October";
        case 10: return "November";
        case 11: return "December";
        default: return undefined;
    }
};

export const dayName = nr => {
    switch(nr) {
        case 0: return "Monday";
        case 1: return "Tuesday";
        case 2: return "Wednesday";
        case 3: return "Thursday";
        case 4: return "Friday";
        case 5: return "Saturday";
        case 6: return "Sunday";
        default: return "Unknown";
    }
};

export default memo(({
    selectedDateRange,
    setSelectedDateRange,
    session
}) => {
    const [visibleMonth, setVisibleMonth] = useState(() => dayjs(selectedDateRange[0]).startOf("month"));

    const [firstDate, lastDate] = useMemo(() => [dayjs(visibleMonth).startOf("month").startOf("isoWeek"), dayjs(visibleMonth).endOf("month").endOf("isoWeek")], [visibleMonth]);

    const visibleMonthName = useMemo(() => monthName(visibleMonth.get("month")), [visibleMonth])

    const [clickedDate, setClickedDate] = useState(null);
    const [hoverDate, setHoverDate] = useState(null);

    const [currentDate, setCurrentDate] = useState(() => dayjs());

    const [notWorkingDates, setNotWorkingDates] = useState([]);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentDate(dayjs());
        }, 60000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const visibleSelectionRange = useMemo(() => {
        if (clickedDate) {
            if (clickedDate.isSameOrBefore(hoverDate, "date")) {
                return [clickedDate, hoverDate];
            } else {
                return [hoverDate, clickedDate];
            }
        } else {
            return selectedDateRange;
        }
    }, [clickedDate, hoverDate, selectedDateRange]);

    useEffect(() => {
        (async () => {
            setNotWorkingDates((await getNotWorkingDates(session.token, firstDate.format("YYYY-MM-DD"), lastDate.format("YYYY-MM-DD"))).data);
        })();
    }, [firstDate, lastDate, session]);

    const visibleWeeks = useMemo(() => {
        const weeks = [[]];
        let week = weeks[0];

        let at = firstDate;
        while (at.isBefore(lastDate)) {
            if (week.length > 6) {
                week = [at];
                weeks.push(week);
            } else {
                week.push(at);
            }
            at = at.add(1, "day");
        }
        return weeks;
    }, [firstDate, lastDate]);

    const setPrevMonth = useCallback(() => {
        setVisibleMonth(visibleMonth.add(-1, "month"));
    }, [visibleMonth]);

    const setNextMonth = useCallback(() => {
        setVisibleMonth(visibleMonth.add(1, "month"));
    }, [visibleMonth]);

    return (
        <div className="calendar panel">
            <div className="header">
                <button onClick={setPrevMonth}><img src={leftIconWhite} alt="Previous month"/></button>
                <div>{visibleMonthName} {visibleMonth.get("year")}</div>
                <button onClick={setNextMonth}><img src={rightIconWhite} alt="Next month"/></button>
            </div>
            <div className="dates">
                {[0, 1, 2, 3, 4, 5, 6].map(nr => (
                    <div key={nr} className="dow">{dayName(nr).substring(0, 3)}</div>
                ))}
                {visibleWeeks.map((week, idx) => (
                    <React.Fragment key={idx}>
                        {week.map(date => (
                            <div
                                key={date.format("YYYY-MM-DD")}
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (!clickedDate) {
                                        setClickedDate(date);
                                        setHoverDate(date);
                                    } else {
                                        setClickedDate(null);
                                        setHoverDate(null);
                                        if (clickedDate.isSameOrBefore(hoverDate, "date")) {
                                            setSelectedDateRange([clickedDate.format("YYYY-MM-DD"), date.format("YYYY-MM-DD")]);
                                        } else {
                                            setSelectedDateRange([date.format("YYYY-MM-DD"), clickedDate.format("YYYY-MM-DD")]);
                                        }
                                    }
                                }}
                                onMouseOver={clickedDate ? (() => {
                                    setHoverDate(date);
                                }):  undefined}
                                title={notWorkingDates.find(n => n.date === date.format("YYYY-MM-DD"))?.description}
                                className={`date${date.isSame(currentDate, "date") ? " current" : ""}${date.isSameOrAfter(visibleSelectionRange[0], "date") && date.isSameOrBefore(visibleSelectionRange[1], "date") ? " active" : ""}${date.isSame(visibleSelectionRange[0], "date") ? " start" : ""}${date.isSame(visibleSelectionRange[1], "date") ? " end" : ""}${notWorkingDates.find(n => n.date === date.format("YYYY-MM-DD")) ? " not-working" : ""}`}
                            >
                                {date.format("DD")}
                            </div>
                        ))}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
});