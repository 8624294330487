import dayjs from "dayjs";
import React, { memo, useMemo, useState } from "react";
import "./AddSupportForm.css";
import saveIcon from "../images/save_white.svg";
import { postSupport } from "../services/api";

export default memo(({
    supportGroups,
    ownUser,
    users,
    token,
    onOk,
    upsertSupport
}) => {
    const [supportGroup, setSupportGroup] = useState(supportGroups.length > 0 ? supportGroups[0].id : 1);
    const [rangeMode, setRangeMode] = useState("false");
    const [startDate, setStartDate] = useState(dayjs().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
    const [saving, setSaving] = useState(false);
    const [user, setUser] = useState(ownUser.userId);

    const disabled = useMemo(() => {
        if (saving) {
            return true;
        }

        if (rangeMode === "true") {
            return startDate != null && endDate != null && startDate > endDate;
        } else {
            return startDate == null;
        }
    }, [startDate, endDate, rangeMode, saving]);

    return (
        <div className="add-support-form">
            <h2>Add support</h2>
            {
                ownUser.admin && (
                    <select value={user} onChange={e => {
                        setUser(parseInt(e.target.value));
                    }}>
                        {
                            users && users.filter(u => !u.disabled).sort((a, b) => {
                                const comp0 = a.firstName.localeCompare(b.firstName);
                                return comp0 === 0 ? a.lastName.localeCompare(b.lastName) : comp0;
                            }).map(u => (
                                <option key={u.userId} value={u.userId}>{u.firstName} {u.lastName}</option>
                            ))
                        }
                    </select>
                )
            }
            <select value={rangeMode} onChange={e => {
                setRangeMode(e.target.value);
            }}>
                <option value="false">Single date</option>
                <option value="true">Date range</option>
            </select>
            <select value={supportGroup} onChange={e => {
                setSupportGroup(parseInt(e.target.value));
            }}>
                {
                    supportGroups.map(g => (<option key={g.id} value={g.id}>{g.name}</option>))
                }
            </select>
            <input type="date" className={rangeMode === "false" ? "single-date" : "range-date"} value={startDate} onChange={e => {
                setStartDate(e.target.value);
            }}/>
            {
                rangeMode === "true" && (
                    <input type="date" className={rangeMode === "false" ? "single-date" : "range-date"} value={endDate} onChange={e => {
                        setEndDate(e.target.value);
                    }}/>
                )
            }
            <button disabled={disabled} onClick={async e => {
                e.preventDefault();
                e.stopPropagation();

                setSaving(true);
                try {
                    if (rangeMode === "true") {
                        let d = dayjs(startDate);
                        const supports = [];
                        while (d.format("YYYY-MM-DD") <= endDate) {
                            const s = (await postSupport(token, {
                                supportGroupId: supportGroup,
                                manual: true,
                                userId: user,
                                date: d.format("YYYY-MM-DD")
                            })).data;
                            supports.push(s);
                            d = d.add(1, "day");
                        }
                        upsertSupport(supports);
                    } else {
                        const s = {
                            supportGroupId: supportGroup,
                            manual: true,
                            userId: user,
                            date: startDate
                        };
                        await postSupport(token, s);
                        upsertSupport(s);
                    }

                    onOk();
                } finally {
                    setSaving(false);
                }
            }}><img src={saveIcon} alt=""/> Save</button>
        </div>
    );
});