import React, { memo, useEffect, useMemo, useState } from "react";
import Modal from "./Modal";
import { createUsersPreset, getIcal, getSlackStatus, getTelavoxIcal, revokeSlackTokens } from "../services/api";
import AddAbsenceForm from "./AddAbsenceForm";
import addIcon from "../images/calendar_add_on_white.svg";
import groupsIcon from "../images/groups_white.svg";
import supportIcon from "../images/support_agent_white.svg";
import saveIcon from "../images/save_white.svg";
import logoutIcon from "../images/logout_white.svg";
import timeIcon from "../images/schedule_white.svg";
import clipboardIcon from "../images/content_copy_white.svg";
import icalIcon from "../images/calendar_apps_script_white.svg";
import addToSlackIcon from "../images/move_down_white.svg";
import removeFromSlackIcon from "../images/delete_white.svg";
import "./ActionsPanel.css";
import AdjustDefaultWorkHoursForm from "./AdjustDefaultWorkHoursForm";
import AddSupportForm from "./AddSupportForm";

export default memo(({
    modalDiv,
    selectedUsers,
    selectedDateRange,
    session,
    logout,
    token,
    ownUser,
    upsertAbsence,
    invalidateUsersPresets,
    defaultWorkHours,
    setDefaultWorkHours,
    supportGroups,
    users,
    upsertSupport
}) => {
    const [creatingPreset, setCreatingPreset] = useState(false);
    const [presetName, setPresetName] = useState("");
    const [creatingAbsence, setCreatingAbsence] = useState(false);
    const [creatingSupport, setCreatingSupport] = useState(false);
    const [adjustingDefaultWorkHours, setAdjustingDefaultWorkHours] = useState(false);
    const [showingTelavoxModal, setShowingTelavoxModal] = useState(false);
    const [showingIcalModal, setShowingIcalModal] = useState(false);
    const [telavoxUrl, setTelavoxUrl] = useState("");
    const [icalUrl, setIcalUrl] = useState("");
    const [slackConnectionStatus, setSlackConnectionStatus] = useState(null);

    const disabled = useMemo(() => {
        return !selectedUsers || selectedUsers.size === 0;
    }, [selectedUsers]);


    useEffect(() => {
        (async () => {
            setSlackConnectionStatus((await getSlackStatus(token)).data);
        })();
    }, [token]);

    return (
        <div className="actions panel">
            <h2>Actions</h2>
            <div className="actions-buttons">
                <button onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setCreatingAbsence(true);
                }}><img alt="" src={addIcon}/> Add absence</button>
                <button onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setCreatingSupport(true);
                }}>
                    <img alt="" src={supportIcon}/> Add support
                </button>
                <button onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setCreatingPreset(true);
                }} disabled={disabled}><img alt="" src={groupsIcon}/> Create user preset</button>
                <button onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setAdjustingDefaultWorkHours(true);
                }}>
                    <img alt="" src={timeIcon}/> Default work hours
                </button>
                <button onClick={async e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setTelavoxUrl("...");
                    setShowingTelavoxModal(true);
                    setTelavoxUrl((await getTelavoxIcal(token)).data);
                }}>
                    <img alt="" src={icalIcon}/> Telavox iCal
                </button>
                <button onClick={async e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIcalUrl("...");
                    setShowingIcalModal(true);
                    setIcalUrl((await getIcal(token, (selectedUsers && selectedUsers.size > 0) ? [...selectedUsers.values()] : users.map(u => u.userId))).data);
                }}>
                    <img alt="" src={icalIcon}/> iCal for selected users
                </button>
                {
                    slackConnectionStatus?.isAlreadyConnected ? (
                        <button onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (slackConnectionStatus != null) {
                                revokeSlackTokens(token);
                                setSlackConnectionStatus(null);
                            }
                        }} disabled={slackConnectionStatus==null}>
                            <img alt="" src={removeFromSlackIcon}/> Disconnect from Slack
                        </button>
                    ) : (
                        <button onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (slackConnectionStatus != null) {
                                document.location.href = slackConnectionStatus.redirectUrl;
                            }
                        }} disabled={slackConnectionStatus==null}>
                            <img alt="" src={addToSlackIcon}/> Connect to Slack
                        </button>
                    )
                }
                <button onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    logout();
                }}>
                    <img alt="" src={logoutIcon}/> Sign out
                </button>
                {
                    creatingPreset && (
                        <Modal modalDiv={modalDiv} onCancel={() => {
                            setCreatingPreset(false);
                            setPresetName("");
                        }}>
                            <h2>Create user preset</h2>
                            <input type="text" onChange={e => {
                                setPresetName(e.target.value);
                            }}/>
                            <div style={{
                                textAlign: "right"
                            }}>
                                <button onClick={async e => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    await createUsersPreset(session.token, presetName, [...selectedUsers.values()]);
                                    setCreatingPreset(false);
                                    setPresetName("");
                                    invalidateUsersPresets();
                                }} disabled={presetName.trim().length === 0}><img src={saveIcon} alt=""/> Save</button>
                            </div>
                        </Modal>
                    )
                }
                {
                    creatingAbsence && (
                        <Modal modalDiv={modalDiv} onCancel={() => {
                            setCreatingAbsence(false);
                        }}>
                            <AddAbsenceForm selectedDateRange={selectedDateRange} session={session} upsertAbsence={upsertAbsence} ownUser={ownUser} onOk={() => {
                                setCreatingAbsence(false);
                            }}/>
                        </Modal>
                    )
                }
                {
                    adjustingDefaultWorkHours && (
                        <Modal modalDiv={modalDiv} onCancel={() => {
                            setAdjustingDefaultWorkHours(false);
                        }}>
                            <AdjustDefaultWorkHoursForm token={token} ownUser={ownUser} defaultWorkHours={defaultWorkHours} setDefaultWorkHours={setDefaultWorkHours} onOk={() => {
                                setAdjustingDefaultWorkHours(false);
                            }}/>
                        </Modal>
                    )
                }
                {
                    creatingSupport && (
                        <Modal modalDiv={modalDiv} onCancel={() => {
                            setCreatingSupport(false);
                        }}>
                            <AddSupportForm upsertSupport={upsertSupport} supportGroups={supportGroups} token={token} users={users} ownUser={ownUser} onOk={() => {
                                setCreatingSupport(false);
                            }}/>
                        </Modal>
                    )
                }
                {
                    showingTelavoxModal && (
                        <Modal modalDiv={modalDiv} onCancel={() => {
                            setShowingTelavoxModal(false);
                        }}>
                            <div  style={{
                                textAlign: "right"
                            }}>
                                <input type="text" value={telavoxUrl}/>
                                <button disabled={telavoxUrl === "..."} onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    navigator.clipboard.writeText(telavoxUrl);
                                }}><img src={clipboardIcon} alt=""/> Copy to clipboard</button>
                            </div>
                        </Modal>
                    )
                }
                {
                    showingIcalModal && (
                        <Modal modalDiv={modalDiv} onCancel={() => {
                            setShowingIcalModal(false);
                        }}>
                            <div  style={{
                                textAlign: "right"
                            }}>
                                <input type="text" value={icalUrl}/>
                                <button disabled={icalUrl === "..."} onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    navigator.clipboard.writeText(icalUrl);
                                }}><img src={clipboardIcon} alt=""/> Copy to clipboard</button>
                            </div>
                        </Modal>
                    )
                }
            </div>
        </div>
    );
});
