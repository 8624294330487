import React, { memo, useCallback, useMemo, useState } from "react";
import { postAbsence } from "../services/api";
import "./AddAbsenceForm.css";
import saveIcon from "../images/save_white.svg";

export default memo(({
    selectedDateRange,
    session,
    onOk,
    upsertAbsence,
    ownUser
}) => {
    const [startDate, setStartDate] = useState(selectedDateRange[0]);
    const [endDate, setEndDate] = useState(selectedDateRange[0]);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [comment, setComment] = useState("");
    const [kind, setKind] = useState("Vacation");
    const [status, setStatus] = useState("confirmed");
    const [saving, setSaving] = useState(false);

    const isDisabled = useMemo(() => {
        if (saving) {
            return true;
        }
        try {
            if (endDate !== "" && new Date(startDate).getTime() > new Date(endDate).getTime()) {
                return true;
            }

            if ((startTime && !endTime) || (!startTime && endTime)) {
                return true;
            }

            if (startTime) {
                if (startDate === endDate && startTime > endTime) {
                    return true;
                }
            }
        } catch (_) {
            return true;
        }
        return false;
    }, [startDate, endDate, startTime, endTime, saving]);

    const onSubmit = useCallback(async e => {
        e.stopPropagation();
        e.preventDefault();

        if (!isDisabled) {
            try {
                setSaving(true);
                var absence = (await postAbsence(session.token, {
                    userId: session.user.userId,
                    startDate,
                    endDate: endDate !== "" ? endDate : null,
                    startTime: startTime !== "" ? startTime : null,
                    endTime: endTime !== "" ? endTime : null,
                    comment,
                    kind,
                    vacationAccepted: kind === "Vacation" ? ownUser.admin : undefined,
                    status
                })).data;

                upsertAbsence(absence);

                if (onOk) {
                    onOk();
                }
            } catch (e) {
                alert(e);
            } finally {
                setSaving(false);
            }
        }
    }, [isDisabled, startDate, endDate, startTime, endTime, kind, session, comment, status, onOk, upsertAbsence, ownUser]);

    return (
        <form className="add-absence-form" disabled={isDisabled} onSubmit={onSubmit}>
            <h2>Add absence</h2>
            <label htmlFor="add-absence-startDate">Start date</label>
            <input id="add-absence-startDate" type="date" value={startDate} onChange={e => {
                setStartDate(e.target.value);
            }}/>
            <label htmlFor="add-absence-startTime">Start time (optional)</label>
            <input id="add-absence-startTime"type="time" value={startTime} onChange={e => {
                setStartTime(e.target.value);
            }}/>
            <label htmlFor="add-absence-endDate">End date (optional)</label>
            <input id="add-absence-endDate"type="date" value={endDate} onChange={e => {
                setEndDate(e.target.value);
            }}/>
            <label htmlFor="add-absence-endTime">End time (optional)</label>
            <input id="add-absence-endTime"type="time" value={endTime} onChange={e => {
                setEndTime(e.target.value);
            }}/>
            <label htmlFor="add-absence-comment">Comment</label>
            <textarea id="add-absence-comment"value={comment} onChange={e => {
                setComment(e.target.value);
            }} rows="5"/>
            <label htmlFor="add-absence-kind">Type</label>
            <select id="add-absence-kind"value={kind} onChange={e => {
                setKind(e.target.value);
            }}>
                <option value="Lunch">Lunch</option>
                <option value="Meeting">Meeting</option>
                <option value="FinishedForTheDay">Finished for the day</option>
                <option value="Vacation">Vacation</option>
                <option value="Sick">Sick</option>
                <option value="TemporarilyOut">Temporarily out</option>
                <option value="WorkTrip">Work trip</option>
                <option value="CareOfChild">Care of child</option>
            </select>
            <label htmlFor="add-absence-status">Certainty</label>
            <select id="add-absence-status"value={status} onChange={e => {
                setStatus(e.target.value);
            }}>
                <option value="Confirmed">Confirmed</option>
                <option value="Preliminary">Preliminary</option>
            </select>
            <button onClick={onSubmit}><img src={saveIcon} alt=""/> Save</button>
            <input style={{
                display: "none"
            }} type="submit" value="Save" disabled={isDisabled}></input>
        </form>
    );
});